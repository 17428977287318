<template>
  <a
    @click="login"
    class="sns-item google"
  >{{message}}</a>
</template>

<script>
export default {
  name: 'GoogleLogin',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  data() {
    return {
      googleAuth: null,
    };
  },
  computed: {
    message() {
      if (this.type === 'signIn') {
        return this.$t('content.auth.GoogleLogin.ButtonSignIn');
      } if (this.type === 'signUp') {
        return this.$t('content.auth.GoogleLogin.ButtonSignUp');
      }
      return '';
    },
  },
  mounted() {
    if (!window.gapi) {
      // Google Sign-In JavaScript client reference : https://developers.google.com/identity/sign-in/web/reference
      // https://stackoverflow.com/a/47002863
      const script = document.createElement('script');
      script.setAttribute('src', 'https://apis.google.com/js/platform.js');
      script.addEventListener('load', () => {
        window.gapi.load('auth2', () => {
          this.initGoogleAuth();
        });
      });

      document.head.appendChild(script);
    } else {
      this.initGoogleAuth();
    }
  },
  methods: {
    initGoogleAuth() {
      this.googleAuth = window.gapi.auth2.init({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        scope: 'email profile',
      });
    },
    login() {
      if (window.gapi) {
        if (!this.googleAuth) {
          this.initGoogleAuth();
        }

        this.googleAuth.signIn().then(() => {
          const current = this.googleAuth.currentUser.get();
          const profile = current.getBasicProfile();
          const userInfo = {
            email: profile.getEmail(),
            name: profile.getName(),
            snsName: this.$t('sns.google'),
            apply_path: '1102',
            access_token: current.getAuthResponse().access_token,
          };
          /**
           * 인증 이벤트
           */
          this.$emit('authenticated', userInfo);
        }, (rej) => {
          console.log(rej.error)
          // refresh
          window.location.reload()
        })
      }
    },
  },
};
</script>

<style scoped>
</style>
